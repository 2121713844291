import { maybe } from '@passionware/monads';
import { useQueryClient } from '@tanstack/react-query';
import { get } from 'lodash';
import { z } from 'zod';
import { Shortlist, Shortlist_normalized } from '../../../../../__types__';
import { ApiClient } from '../../../../../lib/api/ApiClient.types';
import { SHORTLIST_RESOURCE_MAX_COUNT } from '../../../../../v1/containers/Routes/Shortlist/Shortlist';
import { ListQuery, ListResponse } from '../../../common/common.crud.types';
import { parseWithDecoratedError } from '../../../common/parseWithDecoratedError';
import { Resource_normalized } from '../../../resource/resource.types';
import { shortlistInternals } from '../../../shortlist/shortlist.hooks';
import { resourceShortlistKeys } from '../resource__shortlist.keys';
import {
  associationItemsSchema,
  AssociationQuery,
  querySchemas,
  ShortlistBlock_normalized
} from '../resource__shortlist.schemas';

export const createUseResourceShortlistAssociationQueryApi = (
  client: ApiClient,
  useEnsureShortlist: () => (
    id: Shortlist['id']
  ) => Promise<Shortlist_normalized>
) => {
  return () => {
    const queryClient = useQueryClient();
    const ensureShortlist = useEnsureShortlist();

    return {
      list: async (
        query: AssociationQuery
      ): Promise<
        ListResponse<ShortlistBlock_normalized, ListQuery<Resource_normalized>>
      > => {
        if ('shortlist_id' in query) {
          /**
           * We steal data from the shortlist query, but it contains hidden association data that HAS TO BE refetched
           * This is needed since we have dependent queries with hidden data (blocks)
           */
          const dataToStealIsStale = queryClient.getQueryState(
            resourceShortlistKeys.list(query)
          )?.isInvalidated;
          /**
           * We are about read association from shortlist_blocks that is attached to the main shortlist detail endpoint (kept as hidden data)
           * However, since we "normalize" the data, we allow different endpoints to populate the shortlist detail cache.
           * So it is possible that sometimes the hidden data is present, sometimes not, it depends on which view user visited first.
           * So the check below verifies if current shortlist detail cache has hidden data that we may steal.
           * Otherwise, we need to refetch the data, and we know that it will (by coincidence) be sourced from the endpoint
           * That gives us that hidden data
           */
          const noDataToSteal = maybe.isAbsent(
            get(
              queryClient.getQueryData(
                shortlistInternals.keys.detail(query.shortlist_id)
              ),
              'shortlist_blocks'
            )
          );
          if (dataToStealIsStale || noDataToSteal) {
            // if hidden data is absent, or we know that the data is stale, we need to refetch the data before we can read the shortlist_blocks
            queryClient.removeQueries({
              queryKey: shortlistInternals.keys.detail(query.shortlist_id)
            });
          }
          const response = await ensureShortlist(query.shortlist_id);

          // extracting the hidden data from the shortlist detail cache
          const items = parseWithDecoratedError(
            associationItemsSchema,
            response
          );

          return {
            results: items,
            paging: {
              total: items.length,
              page: 0,
              count_per_page: items.length
            },
            query: query.query ?? {}
          };
        }
        if ('resource_id' in query) {
          const response = await client.get(
            `/contacts/${query.resource_id}/associations`,
            {
              token: true
            }
          );

          const shortlistHolderSchema = z
            .object({
              shortlist_blocks: z.array(
                z
                  .object({
                    shortlist: z.object({}).passthrough()
                  })
                  .transform(x => x.shortlist)
              )
            })
            .transform(x => x.shortlist_blocks);

          const extractedShortlist = parseWithDecoratedError(
            shortlistHolderSchema,
            response
          );

          extractedShortlist.forEach(shortlist => {
            shortlistInternals.updateCacheWithItem(
              queryClient,
              shortlist as Shortlist_normalized
            );
          });

          const results = parseWithDecoratedError(
            associationItemsSchema,
            response
          );
          return {
            results: results,
            paging: {
              total: results.length,
              page: 0,
              count_per_page: results.length
            },
            query: {}
          };
        }
        throw new Error('Invalid query');
      },
      search: async ({
        shortlist_id,
        query
      }: z.infer<typeof querySchemas.search.request>) => {
        const tweakedQuery = query ?? {};
        tweakedQuery.count ??= SHORTLIST_RESOURCE_MAX_COUNT;
        tweakedQuery.filters ??= {};
        const result = await client.post(
          `/shortlists/${shortlist_id}/contacts/search`,
          { data: tweakedQuery }
        ); // no token
        return parseWithDecoratedError(querySchemas.search.response, result);
      }
    };
  };
};

export type UseResourceShortlistAssociationQueryApi = ReturnType<
  typeof createUseResourceShortlistAssociationQueryApi
>;

export type ResourceShortlistAssociationQueryApi =
  ReturnType<UseResourceShortlistAssociationQueryApi>;
