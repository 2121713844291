import React, { useEffect, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { isNil } from 'lodash';

import { selectAppId } from 'store/v1/auth/auth.selectors';
import Env from 'lib/env/Env';
import { Icon, LoadingIcon } from 'shared';

import styles from './Button.module.scss';

// Exported for Storybook
export const ButtonAppearances = [
  'silent',
  'transparent',
  'outline',
  'primary',
  'secondary',
  'success',
  'info',
  'warning',
  'danger',
  'dark',
  'sidebar',
] as const;

export type ButtonProps = {
  /**
   * The label to show on the button
   */
  label?: string;
  /**
   * A simple tooltip to show on hover
   */
  tooltip?: string; // TODO: Tooltips
  /**
   * The ARIA label, for accessibility
   */
  ariaLabel?: string;
  /**
   * The icon to show on the button
   */
  icon?: SVGIconName;
  /**
   * A custom color for the icon
   */
  iconColor?: CSSColor;
  /**
   * Show an icon after the label
   */
  rightIcon?: Extract<SVGIconName, 'arrowRight' | 'chevronDown'>;
  /**
   * The button appearance
   */
  appearance?: typeof ButtonAppearances[number];
  /**
   * The button size
   */
  size?: 's' | 'm' | 'l' | 'full';
  /**
   * Add an xs left margin
   */
  hasMarginLeft?: boolean;
  /**
   * Add an xs right margin
   */
  hasMarginRight?: boolean;
  /**
   * Disable the button
   */
  isDisabled?: boolean;
  /**
   * Show the loading icon
   */
  isLoading?: boolean;
  /**
   * Focus the button on load
   */
  focus?: boolean;
  /**
   * `data-id`, for testing
   */
  dataId?: string;
  /**
   * Same as <a> target - use for internal link actions only
   */
  target?: string;
  /**
   * This is basically `event.stopPropagation()`
   */
  stopPropagation?: boolean;
  /**
   * The action to carry out on click
   */
  action?: 'submit' | string | (() => void);
};

enum ButtonActionType {
  Button = 'button',
  Internal = 'internal',
  External = 'external'
}

/**
 * Button-shaped component that has a click action
 */
const Button = ({
  // Display
  label,
  tooltip,
  ariaLabel,
  icon,
  iconColor,
  rightIcon,
  appearance = 'outline',
  size = 'm',
  hasMarginLeft,
  hasMarginRight,
  // State
  isDisabled,
  isLoading,
  focus, // TODO: document
  // Metadata
  dataId,
  // Action
  target,
  stopPropagation,
  action
}: ButtonProps) => {
  const appId = useSelector(selectAppId);
  const ref = useRef<any | null>();
  const isDarkMode = ['primary', 'danger', 'dark'].includes(appearance);
  const buttonIsDisabled = isDisabled || isLoading;

  const buttonProps = {
    ref,
    className: classnames(styles.Button, styles[appearance], {
      [styles[size]]: size !== 'm',
      [styles.square]: icon && isNil(label),
      [styles.disabled]: buttonIsDisabled,
      [styles.loading]: !isDisabled && isLoading,
      [styles.marginLeft]: hasMarginLeft,
      [styles.marginRight]: hasMarginRight
    }),
    'aria-label': ariaLabel,
    disabled: buttonIsDisabled,
    tabIndex: 0,
    'data-id': dataId
  };

  const getComponentType = (): ButtonActionType => {
    if (!action || action === 'submit' || typeof action === 'function') {
      return ButtonActionType.Button;
    }
    if (action.startsWith('http')) {
      return ButtonActionType.External;
    }
    return ButtonActionType.Internal;
  };

  const componentType: ButtonActionType = getComponentType();

  useEffect(() => {
    focus && ref && ref.current && ref.current.focus();
  }, []);

  // PROP WARNINGS
  if (Env.nodeEnv !== 'production') {
    rightIcon &&
      (componentType === ButtonActionType.Internal ||
        componentType === ButtonActionType.External) &&
      console.error(
        `Warning: the rightIcon prop should not be used with a link action type`
      );
    target &&
      componentType !== ButtonActionType.Internal &&
      console.error(
        `Warning: the target prop should only be used with an internal link action type`
      );
  }

  // ACTIONS

  const handleLinkClick = (event: React.SyntheticEvent): void => {
    stopPropagation && event.stopPropagation();
  };

  const handleButtonClick = (event: React.SyntheticEvent): void => {
    stopPropagation && event.stopPropagation();
    if (!buttonIsDisabled) {
      action && typeof action === 'function' && action();
    }
  };

  // RENDERERS

  const showSuffixIcon = (): boolean => {
    return (
      !isNil(label) &&
      (componentType === ButtonActionType.External ||
        componentType === ButtonActionType.Internal ||
        !!rightIcon)
    );
  };

  const suffixIcon: SVGIconName | undefined =
    componentType === ButtonActionType.External
      ? 'arrowTopRight'
      : componentType === ButtonActionType.Internal
        ? 'arrowRight'
        : rightIcon;

  const renderInner = () => (
    <>
      <div className={styles.Content}>
        {icon && (
          <div className={classnames({ [styles.Icon_prefix]: !isNil(label) })}>
            <Icon
              name={icon}
              color={iconColor}
              size={size === 'full' ? 'm' : size}
            />
          </div>
        )}
        {label}
        {showSuffixIcon() && suffixIcon && (
          <div className={styles.Icon_suffix}>
            <Icon name={suffixIcon} size={size === 'full' ? 'm' : size} />
          </div>
        )}
      </div>
      {!isDisabled && isLoading && (
        <div className={styles.LoadingIcon}>
          <LoadingIcon isDarkMode={isDarkMode} />
        </div>
      )}
    </>
  );

  // To properly disable the button it should be a button and not an anchor
  if (
    componentType === ButtonActionType.Internal &&
    typeof action === 'string' &&
    !buttonIsDisabled
  ) {
    const route = action.charAt(0) === '/' ? `/app/${appId}${action}` : action;
    return (
      <HashLink
        to={route}
        target={target}
        smooth
        onClick={handleLinkClick}
        {...buttonProps}
      >
        {renderInner()}
      </HashLink>
    );
  }

  // To properly disable the button it should be a button and not an anchor
  if (
    componentType === ButtonActionType.External &&
    typeof action === 'string' &&
    !buttonIsDisabled
  ) {
    return (
      <a
        href={action}
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleLinkClick}
        {...buttonProps}
      >
        {renderInner()}
      </a>
    );
  }

  return (
    <button
      type={action === 'submit' ? 'submit' : 'button'}
      onClick={handleButtonClick}
      {...buttonProps}
    >
      {renderInner()}
    </button>
  );
};

export default Button;
