import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { unwrapResult } from '@reduxjs/toolkit';

import {
  PressStud,
  ModalWrapper,
  ModalContent,
  ModalNavigation,
  ModalScrollable
} from 'v1/components/shared';
import {
  displayAlert,
  openModal,
  closeModal,
  closeBookingSidebar
} from 'store/v1/ui/ui.actions.js';
import {
  deleteCallout,
  deleteApplicant
} from 'store/v1/callouts/callouts.actions.js';
import {
  archiveContact,
  unarchiveContact,
  archiveContactBatch
} from 'store/v1/contacts/contacts.actions.js';
import { deleteGroup, addToGroup } from 'store/v1/groups/groups.actions.js';
import {
  deleteShortlist,
  updateShortlist
} from 'store/v1/shortlists/shortlists.actions.js';
import {
  deleteProduction,
  unarchiveProduction
} from 'store/v1/productions/productions.actions.js';
import { deleteSlotCategory } from 'store/v1/slot_categories/slot_categories.actions.js';
import { deleteProductionType } from 'store/v1/production_types/production_types.actions.js';
import { deleteResourceType } from 'store/v1/resource_types/resource_types.actions.js';
import { deleteBookingType } from 'store/v1/booking_types/booking_types.actions.js';
import { deleteExpenseType } from 'store/v1/expense_types/expense_types.actions.js';
import { deleteField } from 'store/v1/auth/auth.actions.js';
import { deleteProductionTemplate } from 'store/v1/production_templates/production_templates.actions.js';
import { TAGS_ACTIONS, deleteTag } from 'store/v1/tags';
import { deleteRate } from 'store/v1/rates/rates.actions.js';
import { deleteStatus } from 'store/v1/statuses/statuses.actions.js';
import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';
import { PRODUCTIONS_ACTIONS } from 'store/v1/productions/productions.constants.js';
import { PRODUCTION_TYPES_ACTIONS } from 'store/v1/production_types/production_types.constants.js';
import { RESOURCE_TYPES_ACTIONS } from 'store/v1/resource_types/resource_types.constants.js';
import { BOOKING_TYPES_ACTIONS } from 'store/v1/booking_types/booking_types.constants.js';
import { EXPENSE_TYPES_ACTIONS } from 'store/v1/expense_types/expense_types.constants.js';
import { STATUSES_ACTIONS } from 'store/v1/statuses/statuses.constants.js';
import { get, orderBy } from 'lodash';
import { v4 } from 'uuid';
import { useQueryClient } from '@tanstack/react-query';
import { callsheetKeys } from '../../../../../store/v1/callsheets/callsheets.hooks';
import { callsheets } from '../../../../../store/v1/callsheets/callsheets.hooks.connected';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';
import { Text } from 'shared';

/**
 * @deprecated Use `ConfirmModal` instead
 */
class ConfirmationModal extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.app_id = props.auth.app_id;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = this.props.ui;
    const currStatus = get(this.props.events, this.state.event);
    const nextStatus = get(nextProps.events, this.state.event);

    if (currStatus === EVENT_ACTIONS.PROCESSING) {
      if (nextStatus === EVENT_ACTIONS.SUCCESS) {
        switch (data.action) {
          case 'DELETE_PRODUCTION_TEMPLATE':
            this.props.onRequestClose();
            this.props.displayAlert('success', 'Production template deleted');
            return this.props.history.replace(
              `/app/${this.app_id}/settings/production_templates`
            );
          case 'DELETE_SHORTLIST_BLOCKS':
            this.props.displayAlert('success', 'Shortlist item removed');
            return this.props.onRequestClose();
          case 'DELETE_SHORTLIST':
            this.props.onRequestClose();
            this.props.displayAlert('success', 'Shortlist archived');
            return this.props.history.replace(`/app/${this.app_id}/shortlists`);
          case 'UNARCHIVE_SHORTLIST':
            this.props.onRequestClose();
            return this.props.displayAlert('success', 'Shortlist unarchived');
          case 'UNARCHIVE_PRODUCTION':
            this.props.onRequestClose();
            return this.props.displayAlert('success', 'Production unarchived');
          case 'DELETE_CONTACT':
            this.props.displayAlert('success', 'Contact archived');
            this.props.onRequestClose();
            return this.props.history.replace(`/app/${this.app_id}/resources`);
          case 'UNARCHIVE_CONTACT':
            this.props.onRequestClose();
            return this.props.displayAlert('success', 'Resource unarchived');
          case 'DELETE_PRODUCTION':
            if (data.navigateToRoot) {
              return this.props.history.replace(
                `/app/${this.app_id}/productions`
              );
            } else {
              this.props.onRequestClose();
            }
            return this.props.displayAlert('success', 'Production archived');
          case 'DELETE_CALLOUT':
            this.props.onRequestClose();
            this.props.displayAlert('success', 'Callout archived');
            return this.props.history.replace(`/app/${this.app_id}/callouts`);
          case 'BATCH_GROUP_DELETE':
            this.props.displayAlert('success', 'Group updated');
            break;
          case 'BATCH_CONTACT_DELETE':
            this.props.onRequestClose();
            return this.props.displayAlert('success', 'Contacts removed');
          case 'DELETE_GROUP':
            this.props.onRequestClose();
            return this.props.displayAlert('success', 'Group archived');
          case 'DELETE_APPLICANT':
            this.props.onRequestClose();
            return this.props.displayAlert('success', 'Applicant archived');
          case 'DELETE_CALLSHEET':
            return;
          case 'UPDATE_CALLSHEET':
            this.props.onRequestClose();
            return this.props.displayAlert('success', 'Callsheet updated');
          case 'UPDATE_CALLSHEET_RECIPIENTS':
            this.props.onRequestClose();
            return this.props.displayAlert(
              'success',
              'Callsheet recipients updated'
            );
          case 'SEND_CALLSHEET':
            this.props.onRequestClose();
            // until send callseet is refactored to use react-query we need to invalidate the cache in this modal
            this.props.client.invalidateQueries(callsheetKeys.lists());
            this.props.client.invalidateQueries(
              callsheetKeys.detail(data.callsheet_id)
            );
            return this.props.displayAlert('success', 'Callsheets sent');
          case 'DELETE_TAG':
            this.props.onRequestClose();
            return this.props.displayAlert('success', 'Tag archived');
          case 'DELETE_PRODUCTION_TYPE':
            this.props.onRequestClose();
            return this.props.displayAlert(
              'success',
              'Production type archived'
            );
          case 'DELETE_RESOURCE_TYPE':
            this.props.onRequestClose();
            return this.props.displayAlert('success', 'Resource type archived');
          case 'DELETE_BOOKING_TYPE':
            this.props.onRequestClose();
            return this.props.displayAlert('success', 'Booking type archived');
          case 'DELETE_FIELD':
            this.props.onRequestClose();
            return this.props.displayAlert('success', 'Field archived');
          case 'DELETE_RATE':
            this.props.onRequestClose();
            return this.props.displayAlert('success', 'Rate archived');
          case 'DELETE_EXPENSE_TYPE':
            this.props.onRequestClose();
            return this.props.displayAlert('success', 'Expense type archived');
          case 'DELETE_SLOT_CATEGORY':
            this.props.onRequestClose();
            return this.props.displayAlert('success', 'Category archived');
          default:
            break;
        }
        this.props.onRequestClose();
      } else if (nextStatus !== EVENT_ACTIONS.PROCESSING) {
        this.props.displayAlert('error', nextStatus.message);
      }
    }
  }

  submitAction = () => {
    const { data } = this.props.ui;

    this.setState({ event: v4() }, () => {
      const { event } = this.state;
      switch (data.action) {
        case 'DELETE_PRODUCTION_TEMPLATE':
          return this.props.deleteProductionTemplate(data.id, event);
        case 'DELETE_SHORTLIST':
          return this.props.deleteShortlist(data.shortlist_id, event);
        case 'UNARCHIVE_SHORTLIST':
          return this.props.updateShortlist(
            data.shortlist_id,
            { archived: false },
            event
          );
        case 'UNARCHIVE_CALLSHEET':
          return this.props.unarchiveCallsheetMutation.mutate(
            data.callsheet_id,
            {
              onSuccess: () => {
                this.props.onRequestClose();
                this.props.displayAlert('success', 'Callsheet unarchived');
              }
            }
          );
        case 'UNARCHIVE_PRODUCTION':
          return this.props.unarchiveProduction(data.production_id, event);
        case 'DELETE_CONTACT':
          return this.props.archiveContact(data.contact_id, event);
        case 'UNARCHIVE_CONTACT':
          return this.props.unarchiveContact(data.contact_id, event);
        case 'DELETE_SLOT_CATEGORY':
          return this.props.deleteSlotCategory(data.slot_category_id, event);
        case 'DELETE_CALLOUT':
          return this.props.deleteCallout(data.callout_id, event);
        case 'DELETE_PRODUCTION':
          return this.props.deleteProduction(
            data.production_id,
            PRODUCTIONS_ACTIONS.DELETE_PRODUCTION
          );
        case 'BATCH_GROUP_DELETE':
          return this.props.addToGroup(
            data.group_id,
            data.body,
            'remove',
            event
          );
        case 'BATCH_CONTACT_DELETE':
          return this.props.archiveContactBatch(data.body, event);
        case 'DELETE_GROUP':
          return this.props.deleteGroup(this.props.ui.data.group_id, event);
        case 'DELETE_APPLICANT':
          return this.props.deleteApplicant(data.applicant_id, event);
        case 'DELETE_CALLSHEET':
          return this.props.deleteCallsheetMutation.mutate(data.callsheet_id, {
            onSuccess: () => {
              this.props.onRequestClose();
              this.props.displayAlert('success', 'Callsheet archived');
              return this.props.history.replace(
                `/app/${this.app_id}/callsheets`
              );
            }
          });

        case 'UPDATE_CALLSHEET_RECIPIENTS':
          return this.props.updateRecipientsMutation.mutate(
            {
              id: data.callsheet_id,
              data: data.callsheet.recipients
            },
            {
              onSuccess: () => {
                this.props.onRequestClose();
                this.props.displayAlert('success', 'Recipient removed');
              },
              onError: error => {
                this.props.displayAlert('error', error);
              }
            }
          );
        case 'SEND_CALLSHEET':
          this.props.sendCallsheetMutation.mutate({
            id: data.callsheet_id,
            recipient_ids: data.recipient_ids,
            note: null
          });
          break;
        case 'DELETE_TAG':
          return this.props
            .deleteTag(data.id)
            .then(unwrapResult)
            .then(_ => {
              this.props.displayAlert('success', 'Deleted successfully');
              this.props.closeModal();
            })
            .catch(() => {});
        case 'DELETE_FIELD':
          return this.props.deleteField(data.id, event);
        case 'DELETE_RATE':
          return this.props.deleteRate(data.id, event);
        case 'ROUTE_TO_ROSTER':
          return this.props.history.replace('/contacts');
        case 'DELETE_EXPENSE_TYPE':
          return this.props.deleteExpenseType(
            data.id,
            EXPENSE_TYPES_ACTIONS.DELETE_EXPENSE_TYPE
          );
        case 'DELETE_PRODUCTION_TYPE':
          return this.props.deleteProductionType(
            data.id,
            PRODUCTION_TYPES_ACTIONS.DELETE_PRODUCTION_TYPE
          );
        case 'DELETE_RESOURCE_TYPE':
          return this.props.deleteResourceType(
            data.id,
            RESOURCE_TYPES_ACTIONS.DELETE_RESOURCE_TYPE
          );
        case 'DELETE_BOOKING_TYPE':
          return this.props.deleteBookingType(
            data.id,
            BOOKING_TYPES_ACTIONS.DELETE_BOOKING_TYPE
          );
        case 'DELETE_STATUS':
          return this.props.deleteStatus(
            data.id,
            STATUSES_ACTIONS.DELETE_STATUS
          );
        case 'DELETE_SHORTLIST_BLOCKS':
          const selected = get(this.props.ui, 'data.selected') || [];

          let shortlist = get(this.props.ui, 'data.shortlist', {});

          // Ensure array is ordered correcly before we filter through
          let shortlist_blocks = orderBy(shortlist.shortlist_blocks, 'order');

          // Filter selected blocks out and re-assign new order
          shortlist_blocks = shortlist_blocks
            .filter(b => !selected.includes(b.id) && !b.archived)
            .map((b, index) => ({ ...b, order: index }));

          shortlist.shortlist_blocks = shortlist_blocks;

          return this.props.updateShortlist(shortlist.id, shortlist, event);
        default:
          break;
      }
    });
  };

  isLoadingHandler = () => {
    const loadingType = get(this.props.ui, 'data.action');

    switch (loadingType) {
      case 'DELETE_PRODUCTION_TEMPLATE':
        return (
          this.props.production_templates.loading ===
          'DELETE_PRODUCTION_TEMPLATE'
        );
      case 'UNARCHIVE_SHORTLIST':
        return this.props.shortlists.loading === 'UPDATE_SHORTLIST';
      case 'UNARCHIVE_PRODUCTION':
        return this.props.productions.loading === 'UPDATE_SHORTLIST';
      case 'DELETE_SHORTLIST':
        return this.props.shortlists.loading === 'DELETE_SHORTLIST';
      case 'DELETE_SHORTLIST_BLOCKS':
        return this.props.shortlists.loading === 'UPDATE_SHORTLIST';
      case 'DELETE_CONTACT':
        return this.props.contacts.loading === 'ARCHIVE_CONTACT';
      case 'UNARCHIVE_CONTACT':
        return this.props.bookings.loading === 'UPDATE_CONTACT';
      case 'DELETE_CALLOUT':
        return this.props.callouts.loading === 'DELETE_CALLOUT';
      case 'DELETE_PRODUCTION':
        return this.props.productions.loading === 'DELETE_PRODUCTION';
      case 'BATCH_GROUP_DELETE':
        return this.props.callouts.loading === 'DELETE_CALLOUT';
      case 'BATCH_CONTACT_DELETE':
        return this.props.contacts.loading === 'ARCHIVE_CONTACT_BATCH';
      case 'DELETE_GROUP':
        return this.props.groups.loading === 'DELETE_GROUP';
      case 'DELETE_APPLICANT':
        return this.props.callouts.loading === 'DELETE_APPLICANT';
      case 'DELETE_CALLSHEET':
        return this.props.deleteCallsheetMutation.isLoading;
      case 'UPDATE_CALLSHEET':
        return this.props.callsheets.loading === 'UPDATE_CALLSHEET';
      case 'UPDATE_CALLSHEET_RECIPIENTS':
        return this.props.callsheets?.loading === 'UPDATE_CALLSHEET';
      case 'SEND_CALLSHEET':
        return this.props.callsheets.loading === 'SEND_CALLSHEET';
      case 'DELETE_TAG':
        return this.props.tags.loading === TAGS_ACTIONS.DELETE_TAG;
      case 'DELETE_FIELD':
        return this.props.auth.loading === 'DELETE_FIELD';
      case 'DELETE_RATE':
        return this.props.rates.loading === 'DELETE_RATE';
      case 'DELETE_PRODUCTION_TYPE':
        return this.props.production_types.loading === 'DELETE_PRODUCTION_TYPE';
      case 'DELETE_RESOURCE_TYPE':
        return this.props.resource_types.loading === 'DELETE_RESOURCE_TYPE';
      case 'DELETE_BOOKING_TYPE':
        return this.props.booking_types.loading === 'DELETE_BOOKING_TYPE';
      case 'DELETE_EXPENSE_TYPE':
        return this.props.expense_types.loading === 'DELETE_EXPENSE_TYPE';
      case 'DELETE_STATUS':
        return this.props.statuses.loading === 'DELETE_STATUS';
      case 'DELETE_SLOT_CATEGORY':
        return this.props.slot_categories.loading === 'DELETE_SLOT_CATEGORY';
      case 'ROUTE_TO_ROSTER':
        break;
      default:
        break;
    }
  };

  render() {
    const { data } = this.props.ui;
    return (
      <ModalWrapper className="ConfirmationModal" size="XS">
        <ModalHeader title="Confirm" />
        <ModalContent>
          <ModalScrollable padding="L">
            <Text size="l" stack="s">
              {data.title}
            </Text>
            <Text size="m">{data.text}</Text>
          </ModalScrollable>
          <ModalNavigation>
            <PressStud
              label="Cancel"
              appearance="silent"
              action={this.props.onRequestClose}
            />
            <PressStud
              label={data.button}
              appearance="primary"
              isLoading={this.isLoadingHandler()}
              action={this.submitAction}
            />
          </ModalNavigation>
        </ModalContent>
      </ModalWrapper>
    );
  }
}

function mapStateToProps({
  ui,
  auth,
  contacts,
  contracts,
  projects,
  shortlists,
  callsheets,
  callouts,
  groups,
  tags,
  events,
  rates,
  productions,
  production_types,
  production_templates,
  resource_types,
  booking_types,
  expense_types,
  bookings,
  statuses,
  expenses,
  slot_categories
}) {
  return {
    ui,
    auth,
    contacts,
    contracts,
    projects,
    shortlists,
    callsheets,
    callouts,
    groups,
    tags,
    events,
    rates,
    productions,
    production_types,
    resource_types,
    booking_types,
    expense_types,
    production_templates,
    bookings,
    statuses,
    expenses,
    slot_categories
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteShortlist,
      updateShortlist,
      archiveContact,
      unarchiveContact,
      displayAlert,
      deleteCallout,
      deleteSlotCategory,
      archiveContactBatch,
      deleteGroup,
      addToGroup,
      deleteApplicant,
      openModal,
      closeModal,
      deleteTag,
      deleteField,
      deleteRate,
      deleteProduction,
      unarchiveProduction,
      deleteProductionType,
      deleteProductionTemplate,
      deleteResourceType,
      deleteBookingType,
      deleteExpenseType,
      deleteStatus,
      closeBookingSidebar
    },
    dispatch
  );
}

const withClientAndMutations = WrappedComponent => {
  return function WrapperComponent(props) {
    const client = useQueryClient();
    const deleteCallsheetMutation = callsheets.useDeleteMutation();
    const unarchiveCallsheetMutation = callsheets.useUnarchiveMutation();
    const updateRecipientsMutation = callsheets.useUpdateRecipientsMutation();
    const sendCallsheetMutation = callsheets.useSendMutation();
    return (
      <WrappedComponent
        {...props}
        client={client}
        deleteCallsheetMutation={deleteCallsheetMutation}
        unarchiveCallsheetMutation={unarchiveCallsheetMutation}
        updateRecipientsMutation={updateRecipientsMutation}
        sendCallsheetMutation={sendCallsheetMutation}
      />
    );
  };
};

export default withClientAndMutations(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal))
);
