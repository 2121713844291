import { rd } from '@easle/monads';
import { maybe } from '@passionware/monads';
import { createMatcher } from '@passionware/query-toolkit';
import has from 'lodash/has';
import React, { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Label } from 'v1/components/shared';
import './TagInput.scss';
import { CollectionField } from '../../../../../../v5/common-widgets/CollectionField';
import { useDefaultCreateContent } from '../../../../../../v5/common-widgets/ItemSelector';
import { injectConfig } from '../../../../../../v5/platform/react/injectConfig';

type Tag = {
  id: string | number;
  tag?: string;
  name?: string;
};

type Suggestion = {
  id: string | number;
  tag?: string;
  name?: string;
};

type TagInputProps = {
  className?: string;
  inputClassname?: string;
  label?: string;
  placeholder?: string;
  tags?: Tag[];
  suggestions?: Suggestion[];
  onChange: (tags: Tag[]) => void;
  isReadOnly?: boolean;
  creatable?: boolean;
  sortAlphabetically?: boolean;
  disableAdd?: boolean;
  autocomplete?: boolean;
};

const TagInput: React.FC<TagInputProps> = ({
  label = 'Tags',
  tags: _tags,
  suggestions: _suggestions,
  onChange,
  disableAdd,
  autocomplete,
  creatable = false,
  sortAlphabetically,
  ...props
}) => {
  const tags = _tags || [];
  const suggestions = _suggestions || [];
  const formattedTags = tags.map(t => ({
    id: t.id ? t.id.toString() : `temp-${uuidv4()}`,
    text:
      (has(t, 'tag') ? t.tag : '') ||
      (has(t, 'name') ? t.name : '') ||
      (typeof t === 'string' ? t : '')
  }));
  const formattedSuggestions = (suggestions || [])
    .map(t => ({
      id: t.id ? t.id.toString() : `temp-${uuidv4()}`,
      text:
        (has(t, 'tag') ? t.tag : '') ||
        (has(t, 'name') ? t.name : '') ||
        (typeof t === 'string' ? t : '')
    }))
    .sort((a, b) => {
      return sortAlphabetically
        ? a.text.localeCompare(b.text, 'en', { sensitivity: 'base' })
        : 0;
    })
    .filter(({ id }) => tags.findIndex(tag => +tag.id === +id) === -1);

  const tagsAndSuggestions = [...formattedTags, ...formattedSuggestions];
  return (
    <Fragment>
      {label && <Label>{label}</Label>}
      <SomeCollectionField
        mode={props.isReadOnly ? 'display' : 'edit'} // readOnly={props.isReadOnly}
        data={formattedTags.map(x => x.id)} //tags={formattedTags}
        tagsAndSuggestions={tagsAndSuggestions}
        suggestions={formattedSuggestions}
        // renderSuggestion={item => <div>{item.text}</div>}
        onRemove={(tag, index) => {
          onChange(tags.filter((_, i) => index !== i));
        }}
        // handleDelete={index => {
        //   onChange(tags.filter((_, i) => index !== i));
        // }}
        onAdd={({ id, text }) => {
          //handleAddition
          const parsedId = isNaN(parseInt(id)) ? id : parseInt(id);
          const tag = parsedId
            ? suggestions.find(t => t.id === parsedId)
            : suggestions.find(t => t.tag === text);

          if (tag) {
            onChange([...tags, tag]);
          } else if (
            (creatable && !disableAdd) ||
            (typeof id === 'string' && id.includes('temp'))
          ) {
            onChange([...tags, { tag: text } as Tag]); // todo here we don't pass id which looks mandatory
          }
        }}
        onMove={() => {
          // ???
        }}
        placeholder={props.placeholder || 'Enter tag'}
        // autofocus={false}
        // classNames={{
        //   tagInput: 'TagInput',
        //   tagInputField: classnames('TagInput-input', props.inputClassname),
        //   selected: classnames('TagInput-selected', props.className),
        //   tag: 'TagInput-tag',
        //   remove: 'TagInput-removeButton',
        //   suggestions: 'TagInput-suggestions',
        //   activeSuggestion: 'TagInput-suggestions-active'
        // }}
        // delimiters={[188, 13]}
        // allowDeleteFromEmptyInput={false}
        draggable={false} //allowDragDrop={false}
        // minQueryLength={0}
        // maxLength={40}
        // autocomplete={autocomplete} // ??
        createButtonOnNewLine
        allowCreate={creatable}
      />
    </Fragment>
  );
};

export default TagInput;

type TagOption = {
  id: string;
  text: string;
};

const SomeCollectionField = injectConfig(CollectionField<TagOption>)
  .fromProps<{ suggestions: TagOption[]; tagsAndSuggestions: TagOption[] }>(
    api => ({
      // promise-like function to resolve id to the item
      useResolveById: () => {
        const props = api.useProps();

        return (id: string) =>
          maybe.getOrThrow(
            Promise.resolve(
              maybe.getOrThrow(
                props.tagsAndSuggestions.find(x => x.id === id),
                `Option with id ${id} not found`
              )
            )
          );
      },
      useOptions: query => {
        const props = api.useProps();
        return rd.of(
          props.suggestions
            .filter(createMatcher(query, x => x.text))
            .map(x => ({ id: x.id, label: x.text }))
        );
      },
      promptRenderer: data => (data.length === 0 ? 'Add item' : 'Add'),
      useCreateContent: (query, options) =>
        useDefaultCreateContent(
          query,
          options,
          <>
            Create something: <strong>{query}</strong>
          </>
        ),
      useCreatePromise: () => query =>
        Promise.resolve({ id: 'new', text: query }),
      getId: x => x.id,
      rowRenderer: x => <div>{x.text}</div>,
      useGetGhostItem: () => query => ({ id: 'ghost', text: query }),
      useSelectedItems: keys => {
        const props = api.useProps();
        return rd.of(
          keys.map(key => props.tagsAndSuggestions.find(x => x.id === key)!)
        );
      }
    })
  )
  .transformProps(x => x.skipFields('suggestions', 'tagsAndSuggestions'));
